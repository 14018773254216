<template>
  <v-footer class="pa-3">
    <v-flex text-xs-center xs12>
      <div>&copy; {{ new Date().getFullYear() }}</div>
    </v-flex>
  </v-footer>
</template>

<script>
export default {
  name: "Footer"
};
</script>
